import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Presupuesto from "../components/Presupuesto.vue";
import Login from "../components/Login.vue";
import Viaje from "../components/Viaje.vue";
import AdminViaje from "../components/AdminViaje.vue";
import Usuario from "../views/UsuarioView.vue";
import ReportesView from "../views/ReportesView.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      admin: true,
      user: true,
    },
  },
  {
    path: "/usuario",
    name: "usuario",
    component: Usuario,
    meta: {
      admin: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      libre: true,
    },
  },
  {
    path: "/presupuesto",
    name: "presupuesto",
    component: Presupuesto,
    meta: {
      admin: true,
    },
  },
  {
    path: "/viaje",
    name: "viaje",
    component: Viaje,
    meta: {
      admin: true,
      user: true,
    },
  },
  {
    path: "/adminviaje",
    name: "adminviaje",
    component: AdminViaje,
    meta: {
      admin: true,
    },
  },
  {
    path: "/reporte",
    name: "reporte",
    component: ReportesView,
    meta: {
      admin: true,
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.libre)) {
    next();
  } else if (store.state.user && store.state.user.rol == "ROLE_ADMIN") {
    if (to.matched.some((record) => record.meta.admin)) {
      next();
    }
  } else if (store.state.user && store.state.user.rol == "ROLE_PRESUP") {
    if (to.matched.some((record) => record.meta.admin)) {
      next();
    }
  } else if (store.state.user && store.state.user.rol == "ROLE_USER") {
    if (to.matched.some((record) => record.meta.user)) {
      next();
    }
  } else {
    router.push({ name: "login" });
  }
});

export default router;
