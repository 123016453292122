<template>
  <v-container>
    <v-row>
      <v-col class="d-flex" cols="12" sm="6">
        <v-autocomplete
          :items="unidads"
          item-text="text"
          item-value="value"
          v-model="unidad"
          label="Programa"
          dense
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col class="flex-grow-0 flex-shrink-0" cols="12" sm="6">
        <v-autocomplete
          :items="items"
          item-text="text"
          item-value="value"
          v-model="id_oficina"
          label="Oficina"
          dense
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col class="flex-grow-0 flex-shrink-0" cols="12" sm="6">
        <v-autocomplete
          :items="departamentos"
          item-text="text"
          item-value="value"
          v-model="iddepto"
          label="Departamento"
          dense
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col class="d-flex" cols="12" sm="6">
        <v-autocomplete
          :items="resolucions"
          item-text="text"
          item-value="value"
          v-model="resolucion"
          label="Resolución"
          dense
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col class="d-flex" cols="12" sm="6">
        <v-autocomplete
          :items="transporte"
          item-text="text"
          item-value="value"
          v-model="trans"
          label="Transporte"
          dense
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col class="d-flex" cols="12" sm="6">
        <v-autocomplete
          :items="usuario"
          item-text="text"
          item-value="value"
          v-model="iduser"
          label="Usuario"
          dense
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col class="d-flex" cols="12" sm="6">
        <v-autocomplete
          :items="tipo_viaje"
          item-text="text"
          item-value="value"
          v-model="tv"
          label="Tipo de viaje"
          dense
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col class="d-flex" cols="12" sm="6">
        <v-autocomplete
          :items="estados"
          item-text="text"
          item-value="value"
          v-model="estado"
          label="Estado"
          dense
          outlined
        ></v-autocomplete>
      </v-col>

      <v-col class="d-flex" cols="12" sm="6">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fechaini"
              label="Fecha Inicio"
              persistent-hint
              prepend-icon="calendar_month"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaini"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex" cols="12" sm="6">
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fechafin"
              label="Fecha Fin"
              persistent-hint
              prepend-icon="calendar_month"
              aria-readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechafin"
            no-title
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <div class="text-center">
          <v-btn
            class="ma-2"
            :loading="loading4"
            :disabled="loading4"
            color="primary"
            @click="loader = 'loading4'"
          >
            Generar Reporte
            <!-- <template v-slot:loader>
              <span class="custom-loader">
                <v-icon dark>cached</v-icon>
              </span>
            </template> -->
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="report"
      :search="search"
      sort-by="Descripcion"
      class="elevation-1"
      style="background: rgba(255, 255, 255, 0.8)"
    >
    </v-data-table>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  name: "Reporte",
  data: () => ({
    fechaini: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    fechafin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu1: false,
    menu2: false,
    items: [],
    departamentos: [],
    transporte: [],
    resolucions: [],
    financiamientos: [],
    unidads: [],
    tipo_viaje: [],
    estados: [],
    usuario: [],
    report: [],
    id_oficina: "",
    iddepto: "",
    resolucion: "",
    trans: "",
    iduser: "",
    unidad: "",
    tv: "",
    estado: "",
    loader: null,
    loading4: false,
    search: "",
    headers: [
      { text: "N°", value: "gestion", sortable: true },
      {
        text: "AUTORIZACIÓN DE VIAJE",
        value: "tipo_presupuesto",
        sortable: false,
      },
      {
        text: "NOMBRE/CARGO",
        value: "id_unidad_unidad.descripcion",
        sortable: false,
      },
      {
        text: "PROGRAMA",
        value: "partida_pas",
        sortable: false,
        align: "right",
      },
      {
        text: "LUGAR/TRANSPORTE",
        value: "saldo_pasaje",
        sortable: false,
        align: "right",
      },
      {
        text: "IDA/RETORNO",
        value: "monto_pre_pasaje",
        sortable: false,
        align: "right",
      },
      {
        text: "TIPO VIAJE/RESOLUCIÓN",
        value: "partida_via",
        sortable: false,
        align: "right",
      },
      {
        text: "DÍAS",
        value: "saldo_viaticos",
        sortable: false,
        align: "right",
      },
      {
        text: "VIATICOS/PASAJE",
        value: "monto_pre_viaticos",
        sortable: false,
        align: "right",
      },

      //{text: 'Estado', value: 'estado_presupuesto' ,sortable: false },
    ],
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios.get("oficina/list", conf).then((resp) => {
        resp.data.map((item) => {
          this.items.push({ text: item.nombre, value: item.id });
        });
      });
      axios
        .get("departamento/list", conf)
        .then((response) => {
          response.data.map((item) => {
            this.departamentos.push({
              text: item.departamento,
              value: item.id,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("transporte/list", conf)
        .then((response) => {
          response.data.map((item) => {
            this.transporte.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("unidad/list", conf)
        .then((response) => {
          response.data.map((item) => {
            this.unidads.push({ text: item.descripcion, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios.get("resolucion/list", conf).then((response) => {
        response.data.map((item) => {
          this.resolucions.push({ text: item.descripcion, value: item.id });
        });
      });
      axios
        .get("financiamiento/list", conf)
        .then((response) => {
          response.data.map((item) => {
            this.financiamientos.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("region_nacional/list", conf)
        .then((response) => {
          response.data.map((item) => {
            this.tipo_viaje.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("region_internacional/list", conf)
        .then((response) => {
          response.data.map((item) => {
            this.tipo_viaje.push({ text: item.nombre, value: item.id + 3 });
          });
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("estado/list", conf)
        .then((response) => {
          response.data.map((item) => {
            this.estados.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateReport() {},
    getPerson(id) {},
  },
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
</style>
