<template>
  <v-layout align-start>
    <v-flex>
      <v-data-table
        :headers="headers"
        :items="presupuesto"
        :search="search"
        sort-by="Descripcion"
        class="elevation-1"
        style="background: rgba(255, 255, 255, 0.8)"
      >
        <template #item.monto_pre_pasaje="{ item }">
          {{
            new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(item.monto_pre_pasaje)
          }}
        </template>
        <template #item.monto_pre_viaticos="{ item }">
          {{
            new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(item.monto_pre_viaticos)
          }}
        </template>
        <template #item.saldo_pasaje="{ item }">
          {{
            new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(item.saldo_pasaje)
          }}
        </template>
        <template #item.saldo_viaticos="{ item }">
          {{
            new Intl.NumberFormat("es-BO", {
              minimumFractionDigits: 2,
            }).format(item.saldo_viaticos)
          }}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>PRESUPUESTO PASAJES Y VIATICOS</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar...."
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-dialog v-model="dialog" max-width="795px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left dark>addchart</v-icon>
                    Agregar
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            v-model="editedItem.unidad"
                            :items="unidad"
                            :rules="[(v) => !!v || 'Unidad es Requerido']"
                            label="Unidad"
                            required
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.descripcionpre"
                            label="Descripcion"
                            :rules="[(v) => !!v || 'Descripcion es Requerido']"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-select
                            v-model="editedItem.tipo_presupuesto"
                            :items="tipoppto"
                            :rules="[
                              (v) => !!v || 'Tipo de Presupuesto es Requerido',
                            ]"
                            label="Tipo Presupuesto"
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-select
                            v-model="editedItem.gestion"
                            :items="gestion"
                            label="Gestion"
                            :rules="[(v) => !!v || 'Gestion no valida']"
                            required
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.partida_pas"
                            :items="partidapasajes"
                            label="Partida Pasajes"
                            :rules="[
                              (v) => !!v || 'Partida de Pasaje incorrecta',
                            ]"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            type="number"
                            step="0.1"
                            prefix="Bs"
                            min="0"
                            v-model="editedItem.monto_total_pasaje"
                            label="Monto Total Pasaje"
                            :rules="[(v) => v > 0]"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            type="number"
                            step="0.1"
                            prefix="Bs"
                            min="0"
                            v-model="editedItem.monto_pre_pasaje"
                            label="Monto Presupuesto Pasaje"
                            :rules="[(v) => v > 0]"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.partida_via"
                            :items="partidaviaticos"
                            label="Partida de Viaticos"
                            :rules="[(v) => !!v || 'Partida incorrecta']"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            type="number"
                            step="0.1"
                            prefix="Bs"
                            min="0"
                            v-model="editedItem.monto_total_viaticos"
                            label="Monto Total Viaticos"
                            :rules="[(v) => v > 0]"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            type="number"
                            step="0.1"
                            prefix="Bs"
                            min="0"
                            v-model="editedItem.monto_pre_viaticos"
                            label="Monto Presupuesto Viaticos"
                            :rules="[(v) => v > 0]"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-file-input
                          v-model="informepre"
                          accept="application/pdf"
                          label="Subir informe en formato PDF"
                          :rules="[
                            (v) => !!v || 'Archivo Requerido',
                            (v) =>
                              !v || v.size < 1000000 || 'Tamaño maximo de 1 MB',
                          ]"
                          required
                        ></v-file-input>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid"
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>

            <!-- <v-form ref="form2" v-model="valid2" lazy-validation>

              <v-dialog v-model="modalSubirInforme" max-width="450px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Informe</span>
                  </v-card-title>

                 <v-card-text>
                    <v-file-input
                      v-model="informe"
                      accept="application/pdf"
                      label="Subir informe en formato PDF"
                      :rules="[
                        (v) =>
                          !v || v.size < 1000000 || 'Tamaño maximo de 1 MB',
                      ]"
                      required
                    ></v-file-input>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeInforme">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid2"
                      color="blue darken-1"
                      text
                      @click="saveInforme"
                      >Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>-->
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" @click="bajarPDFpre(item)" color="info">
                picture_as_pdf
              </v-icon>
            </template>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="listmontototal()"> Refrescar </v-btn>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snackbar"
        :timeout="2200"
        bottom
        centered
        rounded="pill"
        :color="color"
        elevation="12"
      >
        <template>
          {{ message }}
        </template>
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import { id } from "vuetify/lib/locale";

export default {
  data() {
    return {
      dialog: false,
      dialogActivate: false,
      dialogDelete: false,
      modalSubirInformepre: false,
      informepre: null,
      color: "",
      snackbar: "",
      message: "",
      cite: null,
      valid: false,
      search: "",
      memo: "",
      unidad: [],
      presupuesto: [],
      gestion: [
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
      ],
      partidapasajes: ["22110"],
      partidaviaticos: ["22210"],
      tipoppto: ["PRESUPUESTO INICIAL", "PRESUPUESTO MODIFICADO"],
      estado: ["VIGENTE", "NO VIGENTE"],
      headers: [
        { text: "Gestion", value: "gestion", sortable: true },
        { text: "Tipo Presupuesto", value: "tipo_presupuesto", sortable: true },
        {
          text: "Unidad",
          value: "id_unidad_unidad.descripcion",
          sortable: true,
        },
        {
          text: "Partida Pasajes",
          value: "partida_pas",
          sortable: false,
          align: "right",
        },
        {
          text: "Saldo Pasaje",
          value: "saldo_pasaje",
          sortable: false,
          align: "right",
        },
        {
          text: "Monto Presu. Pasaje",
          value: "monto_pre_pasaje",
          sortable: false,
          align: "right",
        },
        {
          text: "Partida Viaticos",
          value: "partida_via",
          sortable: false,
          align: "right",
        },
        {
          text: "Monto Viaticos",
          value: "saldo_viaticos",
          sortable: false,
          align: "right",
        },
        {
          text: "Monto Presu. Viaticos",
          value: "monto_pre_viaticos",
          sortable: false,
          align: "right",
        },

        {
          text: "Opciones",
          value: "actions",
          align: "center",
          width: "10%",
          sortable: false,
        },

        //{text: 'Estado', value: 'estado_presupuesto' ,sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        unidad: null,
        presupuesto: null,
        gestion: null,
        tipo_presupuesto: null,
        descripcionpre: null,
        partida_pas: null,
        monto_total_pasaje: null,
        monto_pre_pasaje: null,
        partida_via: null,
        monto_total_viaticos: null,
        monto_pre_viaticos: null,
        //estado_presupuesto: 0,
        color: "",
        snackbar: "",
        message: "",
        cite: "",
      },
      defaultItem: {},
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Registrar Presupuesto" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    modalInforme(val) {
      val || this.closeInf();
    },
  },

  created() {
    this.initialize();
    this.listmontototal();
  },

  methods: {
    initialize() {
      let aux = this;
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios
        .get("unidad/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.unidad.push({ text: item.descripcion, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    listmontototal() {
      let aux = this;
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios
        .get("presupuesto/list", conf)
        .then((response) => {
          console.log(response.data);
          aux.presupuesto = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.presupuesto.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;

      /*if (item.id_unidad) {
        this.estado_presupuesto = 0;
      } else {
        this.estado_presupuesto = 1;
      }*/
    },
    /*subirPDF(item) {
      this.memo = item.id;
      //this.modalSubirInformepre = true;
    },*/
    bajarPDFpre(item) {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios
        .get("presupuesto/filedownload/" + item.id, conf)
        .then((response) => {
          let link = document.createElement("a");
          link.download =
            "INF-PRE-" + item.informepre.replaceAll("/", "-") + ".pdf";
          link.href =
            "data:application/octet-stream;base64," + response.data.archivo;
          link.target = "_blank";
          link.click();
        });
    },

    /*deleteItem(item) {
    this.editedIndex = this.presupuesto.indexOf(item)
    this.editedItem = Object.assign({}, item)
    this.dialogDelete = true
    },*/

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.select = Object.assign({}, this.defaultItem);
        this.$refs.form.reset();
      });
    },
    fileUp(id, file) {
      axios
        .post("file2/" + id, file)
        .then((resp) => {
          this.message = "DATOS REGISTRADOS";
          //this.message = resp.data.message;
        })
        .catch((error) => {
          console.log(error);
          this.message = error.response.data.message;
          /* this.closeInforme();
            this.viajeList(); */
        });
    },
    convString(cad) {
      let palabras = cad.split(" ").map((palabra) => {
        return palabra[0].toUpperCase() + palabra.slice(1).toLowerCase();
      });
      return palabras.join(" ");
    },
    save() {
      let verif = this.$refs.form.validate();
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      let formdata = new FormData();
      formdata.append("file", this.informepre);
      console.log();
      if (verif) {
        axios
          .post(
            "presupuesto/add",
            {
              gestion: this.editedItem.gestion,
              id_unidad: this.editedItem.unidad,
              presupuesto: this.editedItem.presupuesto,
              tipo_presupuesto: this.editedItem.tipo_presupuesto,
              descripcion: this.editedItem.descripcion,
              partida_pas: this.editedItem.partida_pas,
              descripcionpre: this.editedItem.descripcionpre
                .toUpperCase()
                .trim(),
              monto_total_pasaje: this.editedItem.monto_total_pasaje,
              monto_pre_pasaje: this.editedItem.monto_pre_pasaje,
              partida_via: this.editedItem.partida_via,
              monto_total_viaticos: this.editedItem.monto_total_viaticos,
              saldo_viaticos: this.editedItem.monto_total_viaticos,
              saldo_pasaje: this.editedItem.monto_total_pasaje,
              monto_pre_viaticos: this.editedItem.monto_pre_viaticos,
              estado_presupuesto: 1,
              informepre: this.editedItem.informepre,
              user_create: this.$store.state.user._id,
              user_update: this.$store.state.user_id,
              cite: "INFORME PRESUPUESTARIO",
            },
            conf
          )
          .then((data) => {
            this.message = data.data.message;
            this.color = "success";
            this.close();
            this.fileUp(data.data.id, formdata);
            this.listmontototal();
            this.initialize();
            this.snackbar = true;
          })
          .catch((error) => {
            this.message = error.response.data.message;
            this.color = "error";
            this.initialize();
            this.snackbar = true;
            console.error(error);
          });
        this.close();
      }
    },
  },
};
</script>
