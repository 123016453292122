<template>
  <v-container>
    <v-row class="text-center" v-if="isAdmin || isPresup">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logoAEV.png')"
          class="my-3"
          contain
          height="300"
        />
      </v-col>
      <v-col cols="12">
        <GChart
          :type="type"
          :data="data"
          :options="options"
          :settings="settings"
          :resizeDebounce="100"
        />
      </v-col>
    </v-row>
    <v-row class="text-center" v-if="isUser">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logoAEV.png')"
          class="my-3"
          contain
          height="300"
        />
      </v-col>
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Bienvenido al Sistema de Viaticos
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";
import axios from "axios";
export default {
  name: "GoogleChart",
  components: {
    GChart,
  },
  computed: {
    isAdmin() {
      return (
        this.$store.state.user && this.$store.state.user.rol == "ROLE_ADMIN"
      );
    },
    isPresup() {
      return (
        this.$store.state.user && this.$store.state.user.rol == "ROLE_PRESUP"
      );
    },
    isUser() {
      return (
        this.$store.state.user && this.$store.state.user.rol == "ROLE_USER"
      );
    },
  },
  data() {
    return {
      type: "Gauge",
      aux: 0,
      data: [["Label", "Value"]],
      options: {
        max: 100,
        height: 500,
        redFrom: 90,
        redTo: 100,
        yellowFrom: 75,
        yellowTo: 90,
        minorTicks: 10,
      },
      settings: {
        packages: ["gauge"],
      },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      let url;
      let u = await axios.get(
        "usuario/query/" + this.$store.state.user._id,
        conf
      );
      if (this.$store.state.user.rol == "ROLE_ADMIN") {
        url = "viaje/list2";
      } else {
        url = "viaje/list2/" + u.data.unidad;
      }
      await axios
        .get(url, conf)
        .then((response) => {
          /* this.aux = response.data[4].total;
        console.log(this.aux);
        this.options.max = this.aux;
        for (let i = 0; i < response.data.length - 1; i++) {
          response.data[i].nombre = this.convString(response.data[i].nombre);
          this.data.push([response.data[i].nombre, response.data[i].count]);
        }*/
          response.data.map((item) => {
            item.nombre = this.convString(item.nombre);
            this.data.push([item.nombre, item.count]);
          });
        })
        .catch((error) => {});
    },
    convString(cad) {
      let palabras = cad.split(" ").map((palabra) => {
        return palabra[0].toUpperCase() + palabra.slice(1).toLowerCase();
      });
      return palabras.join(" ");
    },
  },
};
</script>
