<template>
  <v-app id="app">
    <span class="bg"></span>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-if="login"
      app
    >
      <v-list dense>
        <template v-if="isAdmin || isUser || isPresup">
          <v-list-item :to="{ name: 'home' }">
            <v-list-item-action>
              <v-icon color="primary">home</v-icon>
            </v-list-item-action>
            <v-list-item-action> Inicio </v-list-item-action>
          </v-list-item>
        </template>
        <template v-if="isAdmin || isUser || isPresup">
          <v-list-item :to="{ name: 'viaje' }">
            <v-list-item-action>
              <v-icon color="primary">map</v-icon>
            </v-list-item-action>
            <v-list-item-action> Registro Viaje </v-list-item-action>
          </v-list-item>
        </template>
        <template v-if="isAdmin || isPresup">
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="primary"> manage_accounts </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Administrador </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'usuario' }">
              <v-list-item-icon>
                <v-icon color="primary"> groups </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Usuarios </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'adminviaje' }">
              <v-list-item-icon>
                <v-icon color="primary"> loyalty </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Modificación Viaticos </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
        <template v-if="isAdmin">
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="primary"> currency_exchange </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Administrador de Presupuesto
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'presupuesto' }">
              <v-list-item-icon>
                <v-icon color="primary"> savings </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Asignacion de Presupuesto </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'reporte' }">
              <v-list-item-icon>
                <v-icon color="primary"> savings </v-icon>
              </v-list-item-icon>
              <v-list-item-title> Reportes </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-img
      :src="require('./assets/PORTADA-VIATICOS-1.png')"
      max-height="250"
      v-if="!login"
    ></v-img>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
      shrink-on-scroll
      fade-img-on-scroll
      prominent
      :src="require('./assets/PORTADA-VIATICOS-2.png')"
      v-if="login"
    >
      <template v-slot:img="{ props }">
        <v-img v-bind="props"></v-img>
      </template>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="login"
        color="success"
      ></v-app-bar-nav-icon>
      <!--<v-toolbar-title v-if="login" class="ml-0 pl-3">
        <span class="hidden-sm-and-down">Viaticos</span>
      </v-toolbar-title>-->
      <v-spacer></v-spacer>
      <v-menu bottom min-width="250px" rounded offset-y v-if="login">
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="brown" size="42">
              <span class="white--text text-h6">{{ user.initial }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar color="brown">
                <span class="white--text text-h5">{{ user.initial }}</span>
              </v-avatar>
              <div style="width: 200px" class="mx-auto text-center">
                <h3>{{ user.name }}</h3>
                <p class="text-caption mt-1">
                  {{ user.cargo }}
                </p>
              </div>
              <v-divider class="my-3"></v-divider>
              <v-btn @click="change()" depressed rounded text>
                <v-icon color="primary">lock_reset</v-icon> Cambiar Contraseña
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn @click="out()" depressed left rounded text>
                <v-icon color="primary">logout</v-icon>Desconectarse
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-slide-y-transition mode="out-in">
          <router-view />
        </v-slide-y-transition>
      </v-container>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-dialog v-model="dialog" max-width="350px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Cambiar Contraseña</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      type="password"
                      v-model="password"
                      label="Contraseña Actual"
                      required
                      :rules="[(v) => !!v || 'Campo es Requerido']"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      type="password"
                      v-model="passwordChange"
                      label="Contraseña Nueva"
                      required
                      :rules="passwordRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      type="password"
                      v-model="passwordConfirm"
                      label="Confirme Contraseña"
                      required
                      :rules="passwordConfirmRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn
                :disabled="!valid"
                color="blue darken-1"
                text
                @click="save"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-snackbar
        v-model="snackbar"
        :timeout="2000"
        bottom
        centered
        rounded="pill"
        :color="color"
        elevation="12"
      >
        <template>
          {{ message }}
        </template>
      </v-snackbar>
    </v-main>
    <v-footer
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
      v-if="login"
    >
      <v-row justify="center" no-gutters>
        <v-col class="py-1 text-center white--text" cols="12">
          <strong>AEVIVIENDA — {{ new Date().getFullYear() }}</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "App",

  data() {
    return {
      snackbar: false,
      dialog: false,
      drawer: true,
      valid: false,
      password: null,
      passwordChange: null,
      passwordConfirm: null,
      color: "",
      message: "",
      user: { name: "", cargo: "", initial: "" },
      passwordRules: [
        (v) => !!v || "Password requerido",
        //^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}$
        (v) =>
          /^(?=(?:.*\d){1})(?=(?:.*[A-Z]){1})(?=(?:.*[a-z]){1})(?=(?:.*[@$?¡\-_*/+&]){1})\S{8,16}$/.test(
            v
          ) ||
          "La contraseña debe contener al menos una letra minúscula, un número, un carácter especial y una letra mayúscula",
      ],
      passwordConfirmRules: [
        (v) => !!v || "Password requerido",
        (v) => v === this.passwordChange || "La contraseña debe ser igual",
      ],
    };
  },
  computed: {
    login() {
      return this.$store.state.user;
    },
    isAdmin() {
      return (
        this.$store.state.user && this.$store.state.user.rol == "ROLE_ADMIN"
      );
    },
    isPresup() {
      return (
        this.$store.state.user && this.$store.state.user.rol == "ROLE_PRESUP"
      );
    },
    isUser() {
      return (
        this.$store.state.user && this.$store.state.user.rol == "ROLE_USER"
      );
    },
  },
  created() {
    this.$store.dispatch("autoLogin");
    if (this.$store.state.token) {
      this.getUser();
    }
  },
  methods: {
    out() {
      this.$store.dispatch("out");
    },
    change() {
      this.dialog = true;
    },
    getUser() {
      let aux = this;
      let id = this.$store.state.user._id;
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios
        .get("usuario/query/" + id, conf)
        .then((data) => {
          aux.user = {
            name: this.convString(data.data.nombre),
            cargo: data.data.cargo,
            initial: this.initialStrinf(data.data.nombre),
          };
        })
        .catch((error) => {
          this.$store.dispatch("out");
          console.log(error);
        });
    },
    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    save() {
      let verfi = this.$refs.form.validate();
      if (verfi) {
        let id = this.$store.state.user._id;
        let header = { Token: this.$store.state.token };
        let conf = { headers: header };
        axios
          .post("usuario/passconf/" + id, { password: this.password }, conf)
          .then((response) => {
            if (response.data.password) {
              axios
                .put(
                  "usuario/changepass/" + id,
                  { password: this.passwordChange },
                  conf
                )
                .then(() => {
                  this.message = "Contraseña actualizada exitosamente";
                  this.color = "success";
                  this.snackbar = true;
                  this.close();
                });
            } else {
              this.message = "Contraseña Incorecta";
              this.color = "red accent-2";
              this.snackbar = true;
            }
          });
      }
    },
    convString(cad) {
      let palabras = cad.split(" ").map((palabra) => {
        return palabra[0].toUpperCase() + palabra.slice(1).toLowerCase();
      });
      return palabras.join(" ");
    },
    initialStrinf(cad) {
      if (cad == "ADMIN") {
        return "A";
      }
      let v = cad.split(" ");
      let initial = v[0].charAt(0) + v[1].charAt(0);
      return initial;
    },
  },
};
</script>
<style>
.bg {
  background: url("./assets/cruz3.png") no-repeat right bottom fixed !important;
  background-size: auto;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.3;
}
</style>
