<template>
  <Usuarios />
</template>
<script>
import Usuarios from "@/components/Usuarios.vue";
export default {
  name: "Usuario",
  components: { Usuarios },
};
</script>
