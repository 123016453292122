<template>
  <v-container>
    <v-layout align-center justify-center pb-14>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-toolbar dark color="blue darken-3">
              <v-toolbar-title> Acceso al Sistema </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="email"
                label="Usuario"
                prepend-icon="person"
                required
              >
              </v-text-field>
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                :append-icon="show ? 'visibility' : 'visibility_off'"
                :type="show ? 'text' : 'password'"
                label="Password"
                prepend-icon="lock"
                @click:append="show = !show"
                @keyup.enter="validate"
                required
              >
              </v-text-field>
              <v-flex class="red--text" v-if="errorM">
                {{ errorM }}
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!valid"
                color="primary"
                class="mr-4"
                @click="validate"
                rounded
              >
                Ingresar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout align-bottom>
      <v-responsive max-height="350" :aspect-ratio="16 / 9">
        <v-carousel
          cycle
          height="auto"
          hide-delimiter-background
          hide-delimiters
          show-arrows-on-hover
          :aspect-ratio="16 / 9"
          ><v-carousel-item v-for="(slide, i) in slides" :key="i">
            <v-img :src="slide.src" contain max-height="350"></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-responsive>
    </v-layout>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      valid: true,
      show: false,
      password: "",
      passwordRules: [(v) => !!v || "Password requerido"],
      email: "",
      errorM: null,
      slides: [
        { src: require("../assets/portada1.png") },
        { src: require("../assets/portada2.png") },
        { src: require("../assets/portada3.png") },
        { src: require("../assets/portada4.png") },
        { src: require("../assets/portada5.png") },
        { src: require("../assets/portada6.png") },
        { src: require("../assets/portada7.png") },
        { src: require("../assets/portada8.png") },
      ],
    };
  },

  methods: {
    validate() {
      let verif = this.$refs.form.validate();
      if (verif) {
        axios
          .post("usuario/login", {
            username: this.email,
            password: this.password,
          })
          .then((user) => {
            return user.data;
          })
          .then((user) => {
            this.$store.dispatch("saveToken", user.token);
            this.$router.push({ name: "home" });
            location.reload();
          })
          .catch((error) => {
            this.errorM = null;
            if (error.response.status == 404) {
              this.errorM = error.response.data.message;
            } else {
              this.errorM = error.response.data.message;
            }
          });
      }
    },
  },
};
</script>
