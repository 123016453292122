<template>
  <v-layout align-start>
    <v-flex>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search2"
        sort-by="nombre"
        class="elevation-1"
        style="background: rgba(255, 255, 255, 0.8)"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>PERSONAL</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search2"
              append-icon="search"
              label="Buscar...."
              single-line
              hide-details
            >
            </v-text-field>
            <v-spacer></v-spacer>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-dialog v-model="dialog" max-width="1000px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left dark>person_add</v-icon> Agregar
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row v-if="editedIndex == -1">
                        <v-autocomplete
                          v-model="model"
                          :items="items"
                          :loading="isLoading"
                          :search-input.sync="search"
                          hide-no-data
                          item-value="ci"
                          item-text="ci"
                          label="Cedula de Identidad"
                          placeholder="Escriba carnet"
                          prepend-icon="search"
                          return-object
                          @change="(valor) => changeState(valor)"
                        ></v-autocomplete>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.nombre"
                            label="Nombre"
                            :rules="nameRules"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.cargo"
                            label="Cargo"
                            :rules="[
                              (v) => !!v || 'Cargo es Requerido',
                              /* (v) =>
                                /^([a-zA-ZÀ-ÿ\u00f1\u00d1]+)(\s[a-zA-ZÀ-ÿ\u00f1\u00d1.]+)*$/.test(
                                  v
                                ) || 'Cargo invalido', */
                            ]"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.ci"
                            label="Cedula de Identidad"
                            :rules="[
                              (v) => !!v || 'Cedula de Identidad es Requerido',
                            ]"
                            required
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.username"
                            label="Username"
                            :rules="[(v) => !!v || 'Username es Requerido']"
                            required
                          >
                          </v-text-field>
                        </v-col> -->
                        <!--<v-col cols="12" sm="6" md="4">
                          <v-text-field
                            type="password"
                            v-model="editedItem.password"
                            label="Contraseña"
                            :rules="[(v) => !!v || 'Contraseña es Requerido']"
                            required
                          >
                          </v-text-field>
                        </v-col>-->
                        <v-col cols="12" sm="6" md="4">
                          <!-- <v-select
                            v-model="editedItem.departamento_trabajo"
                            :items="departamentos"
                            :rules="[(v) => !!v || 'Departamento es Requerido']"
                            label="Departamento"
                            required
                          >
                          </v-select> -->
                          <v-select
                            v-model="editedItem.rol"
                            :items="rol"
                            :rules="[(v) => !!v || 'Rol es Requerido']"
                            label="Rol"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <!-- <v-autocomplete
                            v-model="editedItem.oficina"
                            :items="oficinas"
                            item-text="text"
                            item-value="value"
                            label="Oficina"
                          ></v-autocomplete> -->
                          <v-select
                            v-model="editedItem.unidad"
                            :items="unidades"
                            :rules="[(v) => !!v || 'Unidad es Requerido']"
                            label="Unidad"
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete
                            v-model="editedItem.oficina"
                            :items="oficinas"
                            item-text="text"
                            item-value="value"
                            label="Oficina"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.categoria"
                            :items="categorias"
                            :rules="[(v) => !!v || 'Categoria es Requerido']"
                            label="Categoria"
                            required
                          >
                          </v-select>
                        </v-col>
                        <!--<v-col
                          cols="12"
                          sm="6"
                          md="4"
                          v-if="$store.state.user.rol == 'ROLE_ADMIN'"
                        >
                           <v-select
                            v-model="editedItem.rol"
                            :items="rol"
                            :rules="[(v) => !!v || 'Rol es Requerido']"
                            label="Rol"
                            required
                          > 
                          </v-select>
                        </v-col>-->
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.mail"
                            label="Correo Personal"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.celular"
                            label="Nro Celular"
                            type="number"
                            class="inputPrice"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="!valid"
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">
                  ¿Esta seguro de deshabilitar al personal?
                </v-card-title>
                <v-card-text>
                  <v-textarea
                    v-model="motivo"
                    auto-grow
                    label="Motivo"
                    rows="1"
                  ></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >Aceptar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogActivate" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >¿Esta seguro de habilitar al personal?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="activateItemConfirm"
                    >Aceptar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogPassReset" max-width="550px">
              <v-card>
                <v-card-title class="text-h5"
                  >¿Esta seguro de restablecer la contraseña?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="activatePassReset"
                    >Aceptar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <!--<v-btn class="mx-2" @click="editItem(item)" fab dark small color="primary">
              <v-icon>
                edit
              </v-icon>
            </v-btn>-->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="editItem(item)"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mode_edit</v-icon>
                </v-btn>
              </template>
              <span>Editar Usuario</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.estado">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="deleteItem(item)"
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>done</v-icon>
                </v-btn>
              </template>
              <span>Dar Baja</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="activateItem(item)"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span>Dar Alta</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  class="mr-2"
                  @click="passResetItem(item)"
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>lock_reset</v-icon>
                </v-btn>
              </template>
              <span>Resetear Contraseña</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="userList()"> Refrescar </v-btn>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snackbar"
        :timeout="2200"
        bottom
        centered
        rounded="pill"
        :color="color"
        elevation="12"
        ><template>
          {{ message }}
        </template>
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from "axios";
export default {
  name: "User",
  data() {
    return {
      dialog: false,
      dialogActivate: false,
      dialogDelete: false,
      dialogPassReset: false,
      isLoading: false,
      snackbar: false,
      model: null,
      search2: null,
      valid: false,
      message: "",
      color: "",
      search: null,
      users: [],
      oficinas: [],
      departamentos: [],
      categorias: [],
      unidades: [],
      entries: [],
      categoria: "",
      motivo: "",
      rol: [
        { value: "ROLE_ADMIN", text: "Administrador" },
        { value: "ROLE_PRESUP", text: "Presupuesto" },
        { value: "ROLE_USER", text: "Usuario" },
      ],
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      nameRules: [
        (v) => !!v || "Nombre es Requerido",
        (v) =>
          /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/.test(
            v
          ) || "Nombre invalido",
      ],
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Cargo", value: "cargo", sortable: false },
        { text: "Carnet de Identidad", value: "ci", sortable: false },
        /*{ text: "Usuario", value: "username", sortable: false },
        { text: "Oficina", value: "oficina_oficina.nombre", sortable: false },
         {
          text: "Departamento",
          value: "departamento_trabajo_departamento.departamento",
          sortable: false,
        }, */
        {
          text: "Unidad",
          value: "unidad_unidad.descripcion",
          sortable: false,
        },
        {
          text: "Categoria",
          value: "categoria_categorium.nombre",
          sortable: false,
        },
        {
          text: "Opciones",
          value: "actions",
          width: 130,
          align: "center",
          sortable: false,
        },
      ],
      editedIndex: -1,
      editedItem: {
        nombre: "",
        cargo: "",
        oficina: "",
        categoria: 3,
        username: "",
        password: "",
        ci: "",
        departamento_trabajo: "",
        rol: "ROLE_USER",
        mail: "",
        unidad: "",
        celular: 0,
      },
      defaultItem: {
        nombre: "",
        cargo: "",
        oficina: "",
        categoria: 3,
        username: "",
        password: "",
        ci: "",
        departamento_trabajo: "",
        rol: "ROLE_USER",
        mail: "",
        unidad: "",
        celular: 0,
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    items() {
      return this.entries;
      /* return this.entries.map((entry) => {
        const fullname = entry.fullname;
        return Object.assign({}, entry, { fullname });
      }); */
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search(val) {
      if (this.isLoading) return;
      this.isLoading = true;
      axios
        .get("usuario/buscar/" + val)
        .then((res) => {
          const list = res.data;
          list.map((item) => {
            this.entries.push(item);
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    this.initialize();
    this.userList();
  },
  methods: {
    initialize() {
      let aux = this;
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios
        .get("categoria/list", conf)
        .then((response) => {
          response.data.map((item) => {
            if (item.id != 1)
              aux.categorias.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 403) {
            this.$store.dispatch("out");
          }
        });
      axios
        .get("departamento/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.departamentos.push({ text: item.nombre, value: item.id });
          });
        })
        .catch((error) => {
          //console.log(error);
        });
      axios
        .get("oficina/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.oficinas.push({
              text: item.nombre.toUpperCase(),
              value: item.id,
            });
          });
        })
        .catch((error) => {
          //console.log(error);
        });
      axios
        .get("unidad/list", conf)
        .then((response) => {
          response.data.map((item) => {
            aux.unidades.push({ text: item.descripcion, value: item.id });
          });
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    userList() {
      let aux = this;
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      let url;
      if (this.$store.state.user.rol == "ROLE_ADMIN") {
        url = "usuario/list";
      } else {
        url = "usuario/list2/" + this.$store.state.user._id;
      }
      axios
        .get(url, conf)
        .then(function (response) {
          aux.users = response.data;
        })
        .catch(function (error) {
          //console.log(error);
          if (error.response.status == 403) {
            aux.$store.dispatch("out");
          }
        });
    },
    editItem(item) {
      console.log(item);
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.celular = item.celular;
      this.editedItem.email = item.email;
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios
        .put(
          "usuario/desactivate/" + this.editedItem.id,
          { userupdate: this.$store.state.user._id, motivo: this.motivo },
          conf
        )
        .then(() => {
          this.motivo = "";
          this.userList();
          this.closeDelete();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    activateItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogActivate = true;
    },
    passResetItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPassReset = true;
    },
    activateItemConfirm() {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      axios
        .put(
          "usuario/activate/" + this.editedItem.id,
          { userupdate: this.$store.state.user._id },
          conf
        )
        .then(() => {
          this.userList();
          this.closeDelete();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    activatePassReset() {
      this.passReset(this.editedItem);
    },
    close() {
      this.dialog = false;
      this.entries = [];
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        /* this.selectCat = Object.assign({}, this.defaultItem);
        this.selectRol = Object.assign({}, this.defaultItem);
         */
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.dialogActivate = false;
      this.dialogPassReset = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.select = Object.assign({}, this.defaultItem);
      });
    },
    async save() {
      let verif = this.$refs.form.validate();
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      if (this.$store.state.user.rol != "ROLE_ADMIN") {
        await axios
          .get("usuario/query/" + this.$store.state.user._id, conf)
          .then((response) => {
            this.editedItem.unidad = response.data.unidad;
            this.editedItem.rol = "ROLE_USER";
          });
      }
      if (verif) {
        if (this.editedIndex > -1) {
          axios
            .put(
              "usuario/update/" + this.editedItem.id,
              {
                nombre: this.editedItem.nombre.toUpperCase().trim(),
                cargo: this.editedItem.cargo.toUpperCase().trim(),
                oficina: this.editedItem.oficina,
                categoria: this.editedItem.categoria,
                username: this.editedItem.username.trim(),
                //password: this.editedItem.password.trim(),
                ci: this.editedItem.ci.trim(),
                departamento_trabajo: this.editedItem.departamento_trabajo,
                rol: this.editedItem.rol,
                mail: this.editedItem.mail,
                unidad: this.editedItem.unidad,
                userupdate: this.$store.state.user._id,
                celular: this.editedItem.celular,
              },
              conf
            )
            .then((data) => {
              this.message = data.data.message;
              this.color = "success";
              this.snackbar = true;
              this.close();
              this.userList();
            })
            .catch((error) => {
              this.message = error.response.data.message;
              this.color = "error";
              this.snackbar = true;
              this.close();
              console.log(error);
            });
        } else {
          axios
            .post(
              "usuario/add",
              {
                nombre: this.editedItem.nombre.toUpperCase().trim(),
                cargo: this.editedItem.cargo.toUpperCase().trim(),
                oficina: this.editedItem.oficina,
                categoria: this.editedItem.categoria,
                username: this.editedItem.username.trim(),
                //password: this.editedItem.password.trim(),
                password: "viaticos",
                ci: this.editedItem.ci.trim(),
                departamento_trabajo: this.editedItem.departamento_trabajo,
                rol: this.editedItem.rol,
                mail: this.editedItem.mail,
                unidad: this.editedItem.unidad,
                usercreate: this.$store.state.user._id,
                celular: this.editedItem.celular,
              },
              conf
            )
            .then((data) => {
              this.message = data.data.message;
              this.color = "success";
              this.snackbar = true;
              this.close();
              this.userList();
            })
            .catch((error) => {
              console.log(error);
              this.message = error.response.data.message;
              this.color = "error";
              this.close();
              this.snackbar = true;
            });
        }
        this.close();
      }
    },
    passReset(item) {
      let header = { Token: this.$store.state.token };
      let conf = { headers: header };
      let body = {
        iduser: item.id,
      };
      axios
        .patch("usuario/passreset/" + this.$store.state.user._id, body, conf)
        .then((resp) => {
          this.message = resp.data.message;
          this.color = "success";
          this.snackbar = true;
          this.closeDelete();
          this.userList();
        })
        .catch((err) => {
          this.message = err.response.data.message;
          this.color = "error";
          this.snackbar = true;
          this.closeDelete();
          this.userList();
        });
    },
    changeState(valor) {
      console.log(valor);
      this.editedItem.nombre = valor.fullname;
      this.editedItem.cargo = valor.cargo;
      this.editedItem.ci = valor.ci;
      this.editedItem.mail = valor.email;
      this.editedItem.oficina = parseInt(valor.struct[0].id_struct);
      this.editedItem.departamento_trabajo = parseInt(valor.ine);
      let of = parseInt(valor.idoficina);
      if (of == 5454) this.editedItem.unidad = 1;
      if (of == 5455) this.editedItem.unidad = 6;
      if (of == 5456) this.editedItem.unidad = 2;
      if (of == 5457) this.editedItem.unidad = 5;
      if (of == 5458) this.editedItem.unidad = 3;
      if (of == 5459) this.editedItem.unidad = 4;
      if (of == 5460) this.editedItem.unidad = 7;
      if (of == 5461) this.editedItem.unidad = 10;
      if (of == 5462) this.editedItem.unidad = 9;
      if (of == 5463) this.editedItem.unidad = 8;
      if (parseInt(valor.id_struct) == 34) this.editedItem.unidad = 11;
    },
  },
};
</script>
<style scoped>
.inputPrice >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
