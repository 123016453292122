<template>
  <v-container fluid>
    <Reporte />
  </v-container>
</template>
<script>
import Reporte from "../components/Reporte.vue";
export default {
  components: {
    Reporte,
  },
};
</script>
